import * as React from "react";
export default function Footer() {
    return (React.createElement("footer", { className: "footer" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("div", { className: "footer__message" },
                "\u0415\u0441\u043B\u0438 \u0441\u0435\u0440\u0432\u0438\u0441 \u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442 \u043D\u0435\u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u043E \u0438\u043B\u0438 \u043D\u0430\u00A0\u0441\u0430\u0439\u0442\u0435 \u043D\u0435\u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F, \u043D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443\u00A0",
                React.createElement("a", { href: "https://t.me/apo_help", className: "text-link" }, "@apo_help")),
            React.createElement("div", { className: "footer__message" },
                "\u041D\u0435\u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0432\u0430\u0436\u043D\u044B\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B: ",
                React.createElement("a", { className: "text-link", href: "/img/docs/politika_obrabotki_pd.pdf" }, "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u0432 \u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 (\u0426\u041F\u041C)"),
                ", ",
                React.createElement("a", { className: "text-link", href: "/img/docs/pk.pdf" }, "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u0432 \u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 (\u0410\u041F\u041E)"),
                ", ",
                React.createElement("a", { className: "text-link", href: "/img/docs/soglasie_pd.pdf" }, "\u0421\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445"),
                ", ",
                React.createElement("a", { className: "text-link", href: "/img/docs/politika_pd.pdf" }, "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u0437\u0430\u0449\u0438\u0442\u044B \u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u044B"),
                ", ",
                React.createElement("a", { className: "text-link", href: "/img/docs/pravila_raboty.pdf" }, "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0440\u0430\u0431\u043E\u0442\u044B")))));
}
