import * as React from 'react';
import { useState } from 'react';
import { DocumentsAdminAPI } from '../../api/admin/documents/api';
import RejectCommentForm from './RejectCommentForm';
export default function DocumentCell(props) {
    var _a = useState(false), rejected = _a[0], setRejected = _a[1];
    var _b = useState(false), pending = _b[0], setPending = _b[1];
    function ApproveDocument() {
        setPending(true);
        DocumentsAdminAPI.ApproveOne(props.user.id, props.document.class_id)
            .finally(function () {
            return props.updater();
        })
            .finally(function () {
            setPending(false);
        });
    }
    function RejectDocument() {
        setPending(true);
        DocumentsAdminAPI.RejectOne(props.user.id, props.document.class_id)
            .then(function () { return setRejected(true); })
            .finally(function () { return setPending(false); });
    }
    function IsDocumentInvalid() {
        return props.document.status !== 'ok' && props.document.status !== 'expires soon';
    }
    function GetStatusClassName() {
        if (props.document.status === 'expired') {
            return 'admin-documents-page__document-cell--expired';
        }
        if (props.document.status === 'expires soon' && props.document.is_approved) {
            return 'admin-documents-page__document-cell--expires-soon';
        }
        if (props.document.is_approved) {
            return 'admin-documents-page__document-cell--approved';
        }
        if (props.document.status === 'ok' || props.document.status === 'expires soon') {
            return 'admin-documents-page__document-cell--pending';
        }
        return 'admin-documents-page__document-cell--not-uploaded';
    }
    return (React.createElement("div", { className: 'admin-documents-page__document-cell ' + GetStatusClassName() },
        React.createElement("div", { className: "admin-documents-page__document-name" },
            props.documentName,
            " ",
            props.document.status === 'expires soon' ? '(скоро истечет)' : '',
            " ",
            props.document.status === 'expired' ? '(истек)' : ''),
        React.createElement("span", { className: "admin-documents-page__approved-status", hidden: rejected || !props.document.is_approved || IsDocumentInvalid() },
            React.createElement("span", { className: "material-symbols-outlined document-uploader-component__valid-icon" }, "done"),
            " \u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u043E"),
        React.createElement("span", { className: "admin-documents-page__rejected-status", hidden: !rejected || IsDocumentInvalid() },
            React.createElement("span", { className: "material-symbols-outlined document-uploader-component__invalid-icon" }, "close"),
            " \u041E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u043E"),
        React.createElement("button", { className: "admin-documents-page__button admin-documents-page__button--approve", onClick: ApproveDocument, disabled: pending, hidden: rejected || Boolean(props.document.is_approved) || IsDocumentInvalid() }, "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"),
        React.createElement("button", { className: "admin-documents-page__button admin-documents-page__button--reject", disabled: pending, onClick: RejectDocument, hidden: rejected || IsDocumentInvalid() }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
        React.createElement("div", { hidden: !rejected || IsDocumentInvalid() },
            React.createElement("p", { className: "admin-documents-page__reject-message" }, "\u0415\u0441\u043B\u0438 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u043E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C \u0434\u0440\u0443\u0433\u0438\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0430, \u043B\u0443\u0447\u0448\u0435 \u0441\u0434\u0435\u043B\u0430\u0439\u0442\u0435 \u044D\u0442\u043E \u0441\u0435\u0439\u0447\u0430\u0441. \u041F\u043E\u0442\u043E\u043C \u043E\u043D \u043C\u043E\u0436\u0435\u0442 \u043F\u0435\u0440\u0435\u043C\u0435\u0441\u0442\u0438\u0442\u044C\u0441\u044F \u0432 \u0441\u043F\u0438\u0441\u043A\u0435"),
            React.createElement(RejectCommentForm, { userId: props.user.id })),
        React.createElement("div", { hidden: rejected || (IsDocumentInvalid() && props.document.status !== 'expired') },
            React.createElement("div", { className: "admin-documents-page__files" }, props.document.urls.map(function (url, k) {
                return React.createElement("a", { href: url, target: "_blank", className: "admin-documents-page__file-link" },
                    React.createElement("span", { className: "material-symbols-outlined" }, "description"),
                    React.createElement("span", null, props.document.urls.length > 1 ? k + 1 : 'Файл'));
            })),
            props.textdata.map(function (item, key) {
                return (React.createElement("div", null,
                    React.createElement("span", { className: "admin-documents-page__textdata-name" },
                        item,
                        ":"),
                    " ",
                    props.document.texts[key]));
            })),
        React.createElement("div", { hidden: !IsDocumentInvalid() || props.document.status === 'expired' }, "\u041D\u0435 \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u043E \u0438\u043B\u0438 \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u043E")));
}
