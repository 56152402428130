var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PaperPlane } from '@gravity-ui/icons';
import { Button, Icon, Spin, ThemeProvider } from '@gravity-ui/uikit';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Bot5MiniappAPI } from '../api';
import UserInfoForm, { GetDefaultUserInfoData } from '../forms/UserInfoForm';
import { DoHapticFeedback } from '../index';
export default function Bot5SignupPage() {
    var _a;
    var _b = useState(false), loaded = _b[0], setLoaded = _b[1];
    var _c = useState(true), correctState = _c[0], setCorrectState = _c[1];
    var _d = useState('graduated'), currentGrade = _d[0], setCurrentGrade = _d[1];
    var _e = useState(0), validation = _e[0], setValidation = _e[1];
    var _f = useState(GetDefaultUserInfoData), userInitData = _f[0], setUserInitData = _f[1];
    var lastSavedDataRef = useRef('');
    var currentDataRef = useRef('');
    // @ts-ignore
    var WebApp = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp;
    useEffect(function () {
        import('../telegram.js').finally(function () {
            console.log(loaded);
            var counter = 0;
            var interval = setInterval(function () {
                var _a, _b;
                ++counter;
                if (counter > 30) { // ~1.5 sec
                    clearInterval(interval);
                    setLoaded(true);
                    setValidation(-1);
                }
                // @ts-ignore
                var initData = (_b = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp) === null || _b === void 0 ? void 0 : _b.initData;
                if (initData) {
                    clearInterval(interval);
                    Bot5MiniappAPI.CheckState(initData, 'SIGNUP')
                        .then(function (res) {
                        setValidation(res.valid ? 1 : -1);
                        setCorrectState(res.state_correct);
                        lastSavedDataRef.current = res.state_data;
                        currentDataRef.current = res.state_data;
                        setUserInitData((function () {
                            var defaultValue = GetDefaultUserInfoData();
                            try {
                                var data = JSON.parse(res.state_data);
                                if (typeof data !== "object") {
                                    return defaultValue;
                                }
                                return __assign(__assign({}, defaultValue), data);
                            }
                            catch (e) {
                                return defaultValue;
                            }
                        })());
                    })
                        .finally(function () {
                        setLoaded(true);
                    });
                }
            }, 50);
        });
        var interval = setInterval(function () {
            if (currentDataRef.current !== lastSavedDataRef.current) {
                lastSavedDataRef.current = currentDataRef.current;
                SaveSignupDraft(currentDataRef.current);
            }
        }, 3000);
        return function () { return clearInterval(interval); };
    }, []);
    var SaveSignupDraft = useCallback(function (userInitData) {
        var _a, _b;
        // @ts-ignore
        var initData = (_b = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp) === null || _b === void 0 ? void 0 : _b.initData;
        void Bot5MiniappAPI.SaveDraft(initData, 'SIGNUP', userInitData);
    }, []);
    return (React.createElement("div", { className: "bot5-page" },
        React.createElement(ThemeProvider, { theme: "system" }, validation === -1 || !correctState
            ? (React.createElement("form", { className: "modern bot5-page__form" },
                React.createElement("h1", { className: "bot5-page__small-title", dangerouslySetInnerHTML: {
                        __html: validation === -1
                            ? 'Не&nbsp;прошла проверка подлинности телеграм-сессии. Напишите, пожалуйста, в&nbsp;поддержку'
                            : 'Вы&nbsp;уже заполнили анкету&nbsp;:)'
                    } }),
                validation === -1
                    ? (React.createElement(Button, { selected: true, view: "flat-info", size: "l", width: "max", onClick: function () {
                            DoHapticFeedback(WebApp);
                            location.href = 'https://t.me/apo_help';
                        } },
                        React.createElement(Icon, { data: PaperPlane }),
                        "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C"))
                    : (React.createElement(Button, { selected: true, view: "flat", size: "l", width: "max", onClick: function () {
                            DoHapticFeedback(WebApp);
                            WebApp.close();
                        } }, "\u0421\u0443\u043F\u0435\u0440!"))))
            : ((WebApp && loaded)
                ? (React.createElement(UserInfoForm, { state: "SIGNUP", webApp: WebApp, initData: userInitData, currentDataRef: currentDataRef }))
                : React.createElement(Spin, { size: "l" })))));
}
